<template>
  <div>
    
    <section>
      <Hero>
        <div v-if="loadingHeader">
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Die Vermittlungsdaten werden geladen...</strong>
          </div>
        </div>
        <div v-else-if="unauthorized">
          <div class="alert alert-warning" role="alert">
            <p>Sie haben hierfür keine Berechtigung.</p>
          </div>
        </div>
        <div v-else-if="notfound">
          <div class="alert alert-warning" role="alert">
            <p>Diese Vermittlung konnte nicht ermittelt werden.</p>
          </div>
        </div>
        <div v-else-if="servererror">
          <div class="alert alert-danger" role="alert">
            <p>Es ist ein Fehler aufgetreten.</p>
            <p v-if="error" class="fs-7">{{ error }}</p>
          </div>
        </div>
        <div v-else-if="layout">
          <div class="container">
            <HeadText :titletext="'Mein Zweitmarkt'" :subtext="'Ihr Handelsplatz für geschlossene Fondsbeteiligungen'"></HeadText>
          </div>
          <div class="container bg-light pt-3 px-3 mb-4">
            <b-row>
              <b-col md="6" class="text-left">
                <p>
                  <strong>Ihr Vermittlungsstatus - Detailansicht</strong>
                </p>
                <p>
                  In der Detailansicht sehen Sie, in welchem Stadium sich Ihre Vermittlung aktuell befindet.<br/><br/>
                </p>
              </b-col>
              <b-col md="6" class="text-left">
                <FdbContact></FdbContact>
              </b-col>
            </b-row>
          </div>
          <div class="container container-header vermittlungs-header mt-1 p-3">
            <b-row>
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Vermittlungsnummer</div>
                    <span class="lead font-weight-bold h4-color">{{this.vermittlungsHeaderData.vermittlungNr}}</span>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Status</div>
                    <span class="lead font-weight-bold h4-color fs-10-md-only">
                      {{ this.vermittlungsHeaderData.vermittlungsstatus }}
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">vermittelt am</div>
                    <span class="lead font-weight-bold h4-color">
                      {{ reformatDate( this.vermittlungsHeaderData.vermittlungsdatum )}}
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Beteiligung/Kurs</div>
                    <span class="lead font-weight-bold h4-color">
                      {{ formatPrice( this.vermittlungsHeaderData.nominale.betrag ) }} {{ this.vermittlungsHeaderData.nominale.waehrung }} 
                      &nbsp;/&nbsp; {{ formatPercent( this.vermittlungsHeaderData.kurs ) }} %
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col md="4">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Fonds</div>
                    <span class="lead font-weight-bold h4-color">
                      <router-link :to="{name: 'fondsdetails', params: {client: client, id: this.vermittlungsHeaderData.trancheId}}">
                        {{ this.vermittlungsHeaderData.anbietername }} - {{ fondsNameWithZusatz( this.vermittlungsHeaderData ) }}
                      </router-link>
                    </span>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Auftragsnummer</div>
                    <span class="lead font-weight-bold h4-color">
                      <router-link :to="{name: 'auftragsdetails', params: {client: client, id: this.vermittlungsHeaderData.auftragId}}">
                        <span v-if="this.vermittlungsHeaderData.auftragMakler === 'D'">{{ this.vermittlungsHeaderData.auftragMakler }}</span>{{ this.vermittlungsHeaderData.auftragNr }}
                      </router-link>
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Wirt. Übergang</div>
                    <span class="lead font-weight-bold h4-color">
                      <span v-if="this.vermittlungsHeaderData.wirtuebergang">
                        {{ reformatDate( this.vermittlungsHeaderData.wirtuebergang )}}
                      </span>
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Auszahlungsübergang</div>
                    <span class="lead font-weight-bold h4-color">
                      {{ this.vermittlungsHeaderData.auszahlungsuebergang }}
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Kaufpreis</div>
                    <span class="lead font-weight-bold h4-color">
                      {{ formatPrice( this.vermittlungsHeaderData.kaufpreis.betrag ) }} {{ this.vermittlungsHeaderData.kaufpreis.waehrung }}
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col md="4">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    &nbsp;
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          
          <div class="container pt-3 px-0" v-if="!this.vermittlungsHeaderData.role.directlyConnected">
            <b-row>
              <b-col md="12">
                <b-alert show variant="info" v-if="this.vermittlungsHeaderData.role.id > 50">
                  Sie sind als sichtberechtigter {{ this.vermittlungsHeaderData.role.name }} an dieser Vermittlung beteiligt.
                </b-alert>
                <b-alert show variant="info" v-else>
                  Sie sind als sichtberechtigter Kooperationspartner an dieser Vermittlung beteiligt.
                </b-alert>
              </b-col>
            </b-row>  
          </div>
    
          <div class="container pt-3 px-0" v-if="this.vermittlungsHeaderData.role.directlyConnected && this.vermittlungsHeaderData.role.id !== 60">
            <b-row>
              <b-col md="12">
                <b-alert show variant="info" v-if="this.vermittlungsHeaderData.role.id > 50">
                  Sie sind als {{ this.vermittlungsHeaderData.role.name }} an dieser Vermittlung beteiligt.
                </b-alert>
                <b-alert show variant="info" v-else>
                  Sie sind als Kooperationspartner an dieser Vermittlung beteiligt.
                </b-alert>
              </b-col>
            </b-row>
          </div>

        </div>
  
        <div class="container mt-4 mb-4 pl-0 pr-0" v-if="!unauthorized && !notfound && !servererror && !loadingHistory">
          <b-tabs content-class="mt-3" nav-class="pt-3" pills>

            <b-tab title="Vermittlungsdaten" active>
              <table id="vermittlungsdatentable" role="table" aria-busy="false" aria-colcount="2" class="table b-table table-striped table-bordered">
                <tbody role="rowgroup">
                  <tr role="row">
                    <td aria-colindex="1" role="cell" class="title">
                      <div class="text-left">
                        <strong>
                          Kauf- und Übertragungsvertrag an den Verkäufer verschickt
                        </strong>
                      </div>
                    </td>
                    <td role="cell" class="date">
                      <div class="text-left" v-if="this.vermittlungsHistoryData.vkVersandAm">
                        {{formatDate(this.vermittlungsHistoryData.vkVersandAm)}}
                      </div>
                    </td>
                  </tr>
                  <tr role="row">
                    <td aria-colindex="1" role="cell">
                      <div class="text-left">
                        <strong>
                          Unterschrift Kaufvertrag Verkäufer
                        </strong>
                      </div>
                    </td>
                    <td role="cell">
                      <div class="text-left" v-if="this.vermittlungsHistoryData.unterschrVertragVk">
                        {{formatDate(this.vermittlungsHistoryData.unterschrVertragVk)}}
                      </div>
                    </td>
                  </tr>
                  <tr role="row">
                    <td aria-colindex="1" role="cell">
                      <div class="text-left">
                        <strong>
                          Kauf- und Übertragungsvertrag vom Verkäufer zurück erhalten
                        </strong>
                      </div>
                    </td>
                    <td role="cell">
                      <div class="text-left" v-if="this.vermittlungsHistoryData.vkEmpfangenAm">
                        {{formatDate(this.vermittlungsHistoryData.vkEmpfangenAm)}}
                      </div>
                    </td>
                  </tr>
                  <tr role="row">
                    <td aria-colindex="1" role="cell">
                      <div class="text-left">
                        <strong>
                          Kauf- und Übertragungsvertrag an Käufer verschickt
                        </strong>
                      </div>
                    </td>
                    <td role="cell">
                      <div class="text-left" v-if="this.vermittlungsHistoryData.kVersandAm">
                        {{formatDate(this.vermittlungsHistoryData.kVersandAm)}}
                      </div>
                    </td>
                  </tr>
                  <tr role="row">
                    <td aria-colindex="1" role="cell">
                      <div class="text-left">
                        <strong>
                          Unterschrift Kaufvertrag Käufer
                        </strong>
                      </div>
                    </td>
                    <td role="cell">
                      <div class="text-left" v-if="this.vermittlungsHistoryData.unterschrVertragK">
                        {{formatDate(this.vermittlungsHistoryData.unterschrVertragK)}}
                      </div>
                    </td>
                  </tr>
                  <tr role="row">
                    <td aria-colindex="1" role="cell">
                      <div class="text-left">
                        <strong>
                          Kauf- und Übertragungsvertrag vom Käufer zurück erhalten
                        </strong>
                      </div>
                    </td>
                    <td role="cell">
                      <div class="text-left" v-if="this.vermittlungsHistoryData.kEmpfangenAm">
                        {{formatDate(this.vermittlungsHistoryData.kEmpfangenAm)}}
                      </div>
                    </td>
                  </tr>
                  <tr role="row">
                    <td aria-colindex="1" role="cell">
                      <div class="text-left">
                        <strong>
                          Kaufpreis vom Käufer eingezahlt
                        </strong>
                      </div>
                    </td>
                    <td role="cell">
                      <div class="text-left" v-if="this.vermittlungsHistoryData.kaufpreisEingang">
                        {{formatDate(this.vermittlungsHistoryData.kaufpreisEingang)}}
                      </div>
                    </td>
                  </tr>
                  <tr role="row">
                    <td aria-colindex="1" role="cell">
                      <div class="text-left">
                        <strong>
                          Kauf- und Übertragungsvertrag an Treuhänder verschickt
                        </strong>
                      </div>
                    </td>
                    <td role="cell">
                      <div class="text-left" v-if="this.vermittlungsHistoryData.thVersandAm">
                        {{formatDate(this.vermittlungsHistoryData.thVersandAm)}}
                      </div>
                    </td>
                  </tr>
                  <tr role="row">
                    <td aria-colindex="1" role="cell">
                      <div class="text-left">
                        <strong>
                          Kauf- und Übertragungsvertrag vom Treuhänder zurück erhalten
                        </strong>
                      </div>
                    </td>
                    <td role="cell">
                      <div class="text-left" v-if="this.vermittlungsHistoryData.thEmpfangenAm">
                        {{formatDate(this.vermittlungsHistoryData.thEmpfangenAm)}}
                      </div>
                    </td>
                  </tr>
                  <tr role="row">
                    <td aria-colindex="1" role="cell">
                      <div class="text-left">
                        <strong>
                          Kaufpreis an den Verkäufer ausgezahlt
                        </strong>
                      </div>
                    </td>
                    <td role="cell">
                      <div class="text-left" v-if="this.vermittlungsHistoryData.auszahlungVk">
                        {{formatDate(this.vermittlungsHistoryData.auszahlungVk)}}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>

            <b-tab title="Notiz" v-if="this.vermittlungsHeaderData.canCreateNote">
              <div v-if="this.hasNote" class="tableCard note text-left p-3">
                <p>{{formatDateTime(this.note.lastUpdated)}}</p>
                <p><strong>{{this.note.title}}</strong></p>
                <p class="mb-5">{{this.note.content}}</p>
                <a @click="addNote(vermittlungsHeaderData.id)" class="btn btn-default mr-3 mb-2">Notiz ändern</a>
                <a @click="deleteNote(vermittlungsHeaderData.id)" class="btn btn-danger mb-2">Notiz löschen</a>
                <p class="pt-3">Bitte beachten Sie: Notizen sind nur für Sie sichtbar.</p>
              </div>
              <div v-else class="tableCard text-left p-3">
                <p class="mb-5">Es ist keine Notiz zum Auftrag hinterlegt.</p>
                <a @click="addNote(vermittlungsHeaderData.id)" class="btn btn-default">Notiz anlegen</a>
                <p class="pt-3">Bitte beachten Sie: Notizen sind nur für Sie sichtbar.</p>
              </div>
              <NoteDetails v-bind:objectId="objectId" v-bind:objectType="'VERMITTLUNG'" v-bind:hasNote="this.hasNote" @changeNoteState="setNoteState($event)" v-show="false"></NoteDetails>
            </b-tab>

          </b-tabs>
        </div>
        
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import FdbContact from "@/components/FdbContact";
import axios from "axios";
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";
import reformatDate from "@/mixins/formatDate"
import NoteDetails from "@/components/notes/NoteDetails"

export default {
  name: "Vermittlungsdetails",
  components: {Hero, HeadText, FdbContact, NoteDetails},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
      ],
    }
  },
  data: () => ({
    vermittlungsHeaderData: null,
    error: null,
    loadingHeader: false,
    loadingHistory: true,
    notfound: false,
    unauthorized: false,
    servererror: false,
    layout: true,
    objectId: null,
    objectType: 'VERMITTLUNG',
    hasNote: false,
    noteData: '',
    note: {
      lastUpdated: '',
      title: '',
      content: '',
    }
  }),
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
    noteData: function() {
      if (this.noteData) {
        this.note.lastUpdated = this.noteData.lastUpdated;
        this.note.title = this.noteData.title;
        this.note.content = this.noteData.content;
      }
    }
  },
  computed: {
    mobileViewport() {
      return window.innerWidth < this.$store.getters.getMobileBreakpoint;
    },
    client() {
      return this.$store.getters['clientConfig/client']
    },
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    auftragDetailsUrl( aid ) {
      return "/auftragsdetails/" + aid;
    },
    fondsDetailsUrl( tid ) {
      return "/fondsdetails/" + tid;
    },
    fondsNameWithZusatz( dataItem ) {
      if ( dataItem.fondsnamezusatz && dataItem.fondsnamezusatz !== '' ) {
        return dataItem.fondsname + ' - ' + dataItem.fondsnamezusatz;
      }
      return dataItem.fondsname
    },
    fetchData() {
      if (!this.$store.getters["auth/hasToken"]) {
        this.loadingHeader = false;
        this.loadingHistory = false;
        this.unauthorized = true;
        return;
      }
      this.loadingHeader = true;
      this.loadingHistory = true;
      const vermittlungId = this.$route.params.id
      var config = null;
      if (this.$store.getters["auth/hasToken"]) {
        const token = this.$store.getters["auth/getToken"];
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        };
      } else {
        config = {
        };
      }
      axios.get( this.$store.getters.getAllEnvis.API_VERMITTLUNGHEADER.replace('{vermittlungId}',vermittlungId), config )
          .then( response => {
            this.vermittlungsHeaderData = response.data;
            this.loadingHeader = false;
          }, error => {
            if (error.response.status === 401) {
              this.unauthorized = true;
            }
            else if ( error.response.status === 404 ) {
              this.notfound = true;
              this.$router.push('/errorPage')
            } else if ( error.response.status === 400 ) {
              console.log("This is a 400 error!")
              this.$router.push('/errorPage')
            } else {
              this.servererror = true;
            }
            this.loadingHeader = false;
            this.error = error;
            return error;
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
            this.loadingHeader = false;
          })


      // -> Umbau zu den Vermittlungsdatumswerten
      axios.get( this.$store.getters.getAllEnvis.API_VERMITTLUNGHISTORIE.replace('{vermittlungId}',vermittlungId), config )
          .then( response => {
            this.vermittlungsHistoryData = response.data;
            this.loadingHistory = false;
          }, error => {
            if (error.response.status === 401) {
              this.unauthorized = true;
            }
            else if ( error.response.status === 404 ) {
              this.notfound = true;
              this.$router.push('/errorPage')
            } else if ( error.response.status === 400 ) {
              console.log("This is a 400 error!")
              this.$router.push('/errorPage')
            } else {
              this.servererror = true;
            }
            this.loadingHistory = false;
            this.error = error;
            return error;
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
            this.loadingHistory = false;
          })


      axios.get( this.$store.getters.getAllEnvis.API_USER_VERMITTLUNGNOTE.replace('{vermittlungId}', vermittlungId), config )
          .then( response => {
            this.noteData = response.data;
            this.hasNote = true;
          }, error => {
            if (error.response.status === 404) {
              this.noteData = null;
              this.hasNote = false;
            } else {
              this.error = error;
              return error;
            }
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
          })


    },
    fetchNote() {
      const vermittlungId = this.$route.params.id
      var config = null;
      if (this.$store.getters["auth/hasToken"]) {
        const token = this.$store.getters["auth/getToken"];
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        };
      } else {
        config = {
        };
      }
      axios.get( this.$store.getters.getAllEnvis.API_USER_VERMITTLUNGNOTE.replace('{vermittlungId}', vermittlungId), config )
          .then( response => {
            this.noteData = response.data;
            this.hasNote = true;
          }, error => {
            if (error.response.status === 404) {
              this.noteData = null;
              this.hasNote = false;
            } else {
              this.error = error;
              return error;
            }
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
          })
    },
    addNote(id) {
      this.objectId = id;
      this.noteData = this.$store.getters["notes/getNoteData"];
      this.$bvModal.show( 'noteDetails' );
    },
    deleteNote(id){
      const payload = {
        objectId: id,
        objectType: 'VERMITTLUNG',
      }
      this.$store.dispatch('notes/deleteNoteData', payload);
      this.hasNote = false;
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    formatDateTime(n) {
      if(n != null) {
        // Date
        const date = n.split("T")[0]
        const formattedDate = date.split("-")

        // Time
        const timeString = n.split("T")[1]
        const formattedTime = timeString.split(".")[0]
        const shortenedTime = formattedTime.split(":")

        return formattedDate[2] + "." + formattedDate[1] + "." + formattedDate[0] + " - " + shortenedTime[0] + ":" + shortenedTime[1];
      }
    },
    formatDate(n) {
      if(n !== null) {
        // Date
        const date = n.split("T")[0]
        const formattedDate = date.split("-")

        return formattedDate[2] + "." + formattedDate[1] + "." + formattedDate[0];
      }
    },
    setNoteState(noteState) {
      this.hasNote= noteState;
    },
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if ( "noteDetails" === modalId ) {
        this.fetchNote();
      }
    })
  },
  mixins: [priceMixin,percentMixin,reformatDate],
}
</script>

<style scoped>


</style>